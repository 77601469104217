import { useSelector } from "react-redux";
import { InitialState, MessageType } from "../redux/interfaces";
import ClipboardJS from "clipboard";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { BlockMath, InlineMath } from "react-katex";
import "./css/chat.css";
import "./css/message.css";
import "./css/codeBlock.css";
import "./css/panel.css";
import "./css/textarea.css";
import "highlight.js/styles/atom-one-dark.css";
import "katex/dist/katex.min.css";

export function MessageComponent({ message }: { message: MessageType }) {
  const copyMode = useSelector((state: InitialState) => state.copyMode);
  // const langsRegex = /```(\w+)\n[\s\S]*?```/g;
  // const langsMathes = message.text.match(langsRegex);
  // let renderedCodesCount = 0;
  // const langs = langsMathes?.map((match) => {
  //   return match.substring(3, match.indexOf("\n"));
  // });
  const langColors: { [key: string]: string } = {
    js: "#fff200",
    py: "#00ff22",
    cpp: "#00b3ff",
    php: "#c300ff",
    html: "#00ff22",
    css: "#00b3ff",
    java: "#fff200",
    kt: "#c300ff",
    cs: "#c300ff",
    go: "#00ff22",
    javascript: "#fff200",
    python: "#00ff22",
    kotlin: "#c300ff",
    csharp: "#c300ff",
  };

  const onCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    const button = e.target as HTMLButtonElement;
    button.textContent = "Copied";

    setTimeout(() => {
      button.textContent = "Copy";
    }, 500);
  };

  const renderers = {
    code: ({ node, inline, className, children, ...props }: any) => {
      const match = /language-(\w+)/.exec(className || "");

  
      console.log(match ? match[1] : "nul");

      if (!inline && match) {
      
        new ClipboardJS(".copy-button");
      }

      return (
        <>
          {!match ? (
            <>{String(children).replace(/\n$/, "")}</>
          ) : (
            <div className="code-box">
              <div className="head">
                <div
                  style={{ color: match[1] ? langColors[match[1]] : "white" }}
                  className="lang-name"
                >
                  {match[1] || "ruby"}
                </div>
                <button
                  onClick={onCopy}
                  className="copy-button"
                  data-clipboard-text={String(children).replace(/\n$/, "")}
                >
                  Copy
                </button>
              </div>
              <pre>
                <code
                  style={{ cursor: copyMode ? "copy" : "text" }}
                  className={`language-${match[1] || "ruby"}`}
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </code>
                {!window.localStorage.getItem("addedToDis") && (
                  <div className="discord-ad-parent">
                    <div
                      onClick={() => {
                        window.localStorage.setItem("addedToDis", "1");
                        window.location.href = "https://discord.gg/Rv7uY5WHVT";
                      }}
                      className="discord-ad"
                    >
                      <p>
                        Если ты программист, хочешь развиваться и ищешь
                        единомышленников, то заходи на наш Discord сервер
                      </p>
                      <div className="ds-svg">
                        <svg
                          viewBox="0 0 33 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M28.9279 2.99689C24.8844 -0.0860433 21.0102 0.000602527 21.0102 0.000602527L20.6143 0.461514C25.4214 1.93089 27.6554 4.09164 27.6554 4.09164C20.7777 0.233575 12.0173 0.261258 4.8636 4.09164C4.8636 4.09164 7.1823 1.81549 12.2724 0.346106L11.9898 0.000242514C11.9898 0.000242514 8.14387 -0.0860433 4.07211 2.99653C4.07211 2.99653 0 10.4585 0 19.6494C0 19.6494 2.37552 23.7983 8.62484 24C8.62484 24 9.67109 22.7323 10.5194 21.6376C6.92824 20.5428 5.57075 18.2666 5.57075 18.2666C7.827 19.7051 10.1083 20.6054 12.9514 21.1766C17.5768 22.1466 23.3303 21.1493 27.6276 18.2666C27.6276 18.2666 26.2136 20.6003 22.5092 21.6663C23.3575 22.7323 24.3755 23.9712 24.3755 23.9712C30.6248 23.7695 33 19.6206 33 19.6497C32.9996 10.4588 28.9279 2.99689 28.9279 2.99689ZM11.2262 16.9702C9.64251 16.9702 8.34183 15.5583 8.34183 13.801C8.45758 9.59202 14.0237 9.60496 14.1105 13.801C14.1105 15.5583 12.8377 16.9702 11.2262 16.9702ZM21.5473 16.9702C19.9636 16.9702 18.6629 15.5583 18.6629 13.801C18.7899 9.60029 24.2866 9.59849 24.4316 13.801C24.4316 15.5583 23.1592 16.9702 21.5473 16.9702Z"
                            fill="#6665D2"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                )}
              </pre>
            </div>
          )}
        </>
      );
    },
  };
  const renderMath = (text: string) => {
    const mathRegex = /\$\$([^$]+)\$\$|\$([^$]+)\$/g; // регулярное выражение для поиска блочных и встроенных формул

    let lastIndex = 0;
    let match;
    const parts: React.ReactNode[] = [];

    while ((match = mathRegex.exec(text)) !== null) {
      if (match.index > lastIndex) {
        parts.push(
          <ReactMarkdown
            children={text.substring(lastIndex, match.index)}
            remarkPlugins={[remarkGfm]}
            components={renderers}
            key={match.index}
          />
        );
      }

      const mathContent = match[1] || match[2]; // match[1] для блочных формул, match[2] для встроенных
      if (match[0].startsWith("$$") && match[0].endsWith("$$")) {
        parts.push(<BlockMath key={match.index} math={mathContent} />);
      } else {
        parts.push(<InlineMath key={match.index} math={mathContent} />);
      }

      lastIndex = mathRegex.lastIndex;
    }
    if (lastIndex < text.length) {
      parts.push(
        <ReactMarkdown
          children={text.substring(lastIndex)}
          remarkPlugins={[remarkGfm]}
          components={renderers}
          key={lastIndex}
        />
      );
    }

    return parts;
  };

  return <>{renderMath(message.text)}</>;
}
