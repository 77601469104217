import React, { Children, ReactNode, useEffect, useState } from "react";
import "./basePage.css";
import { useDispatch } from "react-redux";
import {
  setBgImage,
  setShowChat,
  setShowLoginForm,
  setShowPayForm,
  setWinSize,
} from "../redux/actions";
import { useSelector } from "react-redux";
import { InitialState } from "../redux/interfaces";
import axios from "axios";
import { DOMAIN } from "../App";
declare global {
  interface Window {
    YooMoneyCheckoutWidget: any;
  }
}
export function PayForm() {
  const dispatch = useDispatch();
  const me = useSelector((state: InitialState) => state.me);
  const [yoomoneyToken, setYoomoneyToken] = useState(null);
  const onClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLElement).classList.contains("dark-bg"))
      dispatch(setShowPayForm(false));
  };
  const [form, setForm] = useState({
    email: me?.email,
    summa: 50, // минимальная сумма
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const onSend = (e: any) => {
    e.preventDefault();
    axios
      .post(DOMAIN + "api/users/replenish_balance/", form)
      .then((resp) => {
        setYoomoneyToken(resp.data.confirmation_token);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (yoomoneyToken) {
      const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: yoomoneyToken,
        return_url: "http://gpt-photo.ru/",
        customization: {
          //Настройка способа отображения
          modal: true,
        },
        error_callback: function (error: any) {
          console.log(error);
        },
      });

      //Отображение платежной формы в контейнере
      checkout.render("payment-form");
    }
  }, [yoomoneyToken]);
  return (
    <div onClick={onClose} className="dark-bg">
      <form onSubmit={onSend} className="pay-form">
        <h1>Пополнение баланса</h1>
        <div className="field-parent">
          <p>Введите почту, это необходимо для отправки чеков о Вашей оплате</p>
          <div className="field">
            <input
              required
              placeholder="Введите email"
              type="email"
              name="email"
              onChange={handleInputChange}
              value={form.email}
            />
          </div>
        </div>
        <div className="field-parent">
          <p>Введите сумму для пополнения(мин. сумма пополнения 50 руб)</p>
          <div className="field">
            <input
              required
              placeholder="Введите сумму"
              type="number"
              name="summa"
              min={50}
              onChange={handleInputChange}
              value={form.summa}
            />
          </div>
        </div>
        <button>Продолжить</button>
      </form>
    </div>
  );
}

export function Header() {
  const dispatch = useDispatch();
  const showChat = useSelector((state: InitialState) => state.showChat);
  const me = useSelector((state: InitialState) => state.me);
  const toggleChat = () => {
    if (!me) {
      dispatch(setShowLoginForm(true));
      dispatch(setShowChat(!showChat));
    } else {
      dispatch(setShowChat(!showChat));
    }
    console.log(showChat);
  };
  const scrollTo = (cssSelectior: string) => {
    dispatch(setShowChat(false));
    setTimeout(() => {
      const obj = document.querySelector(cssSelectior) as HTMLElement;
      if (obj) {
        obj.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  const mainButton = [
    { name: "Главная", cssSelectior: ".main-parent" },
    { name: "Что может?", cssSelectior: ".skills-parent" },
    { name: "Тарифы", cssSelectior: ".tariffs-parent" },
    { name: "Сколько нас?", cssSelectior: ".flip-clock" },
    { name: "Вопросы", cssSelectior: ".questions-parent" },
  ];
  const [showInfo, setShowInfo] = useState(false);
  const logout = () => {
    axios
      .post(DOMAIN + "api/users/logout/")
      .then((resp) => {
        window.localStorage.setItem("showChat", "0");
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  return (
    <header>
      <div onClick={() => dispatch(setShowChat(false))} className="logo">
        <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_86_129)">
            <path
              d="M2.07741 29.0837C0.930087 29.0837 0 30.0138 0 31.1611V35.316C0 36.4633 0.930087 37.3934 2.07741 37.3934C3.22472 37.3934 4.15482 36.4633 4.15482 35.316V31.1611C4.15482 30.0138 3.22472 29.0837 2.07741 29.0837Z"
              fill="white"
            />
            <path
              d="M45.7031 31.1611C45.7031 30.0138 46.6332 29.0837 47.7805 29.0837C48.9279 29.0837 49.8579 30.0138 49.8579 31.1611V35.316C49.8579 36.4633 48.9279 37.3934 47.7805 37.3934C46.6332 37.3934 45.7031 36.4633 45.7031 35.316V31.1611Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.9286 2.07739C22.6339 2.07739 20.7738 3.93757 20.7738 6.23221C20.7738 7.77007 21.6093 9.11281 22.8512 9.83119V14.5418H12.4642C9.02221 14.5418 6.23193 17.3321 6.23193 20.7741V41.5482C6.23193 44.9902 9.02221 47.7804 12.4642 47.7804H37.3931C40.8351 47.7804 43.6253 44.9902 43.6253 41.5482V20.7741C43.6253 17.3321 40.8351 14.5418 37.3931 14.5418H27.006V9.83119C28.2479 9.11281 29.0834 7.77007 29.0834 6.23221C29.0834 3.93757 27.2233 2.07739 24.9286 2.07739ZM10.3868 20.7741C10.3868 19.6268 11.3168 18.6967 12.4642 18.6967H15.3351L18.3415 24.7095C19.0453 26.1172 20.484 27.0063 22.0576 27.0063H27.7996C29.3732 27.0063 30.8118 26.1172 31.5157 24.7095L34.5221 18.6967H37.3931C38.5404 18.6967 39.4705 19.6268 39.4705 20.7741V41.5482C39.4705 42.6955 38.5404 43.6256 37.3931 43.6256H12.4642C11.3168 43.6256 10.3868 42.6955 10.3868 41.5482V20.7741ZM27.7996 22.8515L29.877 18.6967H19.9803L22.0576 22.8515H27.7996Z"
              fill="white"
            />
            <path
              d="M18.6965 31.1611C17.5492 31.1611 16.6191 32.0912 16.6191 33.2385C16.6191 34.3859 17.5492 35.3159 18.6965 35.3159C19.8439 35.3159 20.774 34.3859 20.774 33.2385C20.774 32.0912 19.8439 31.1611 18.6965 31.1611Z"
              fill="white"
            />
            <path
              d="M29.0835 33.2385C29.0835 32.0912 30.0136 31.1611 31.1609 31.1611C32.3083 31.1611 33.2383 32.0912 33.2383 33.2385C33.2383 34.3859 32.3083 35.3159 31.1609 35.3159C30.0136 35.3159 29.0835 34.3859 29.0835 33.2385Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_86_129">
              <rect width="49.8578" height="49.8578" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1>GPT-Photo</h1>
      </div>
      <div className="button-parent">
        {mainButton.map((button, index) => (
          <button key={index} onClick={() => scrollTo(button.cssSelectior)}>
            <h1>{button.name}</h1>
          </button>
        ))}
      </div>
      {me ? (
        <div className="me">
          <div className="chat-svg">
            <svg
              onClick={toggleChat}
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.4 18.1502H28.6"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M13.4 24.8H23.85"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
              <path
                d="M30.5 4.54186C27.7053 2.92524 24.4607 2 21 2C10.5066 2 2 10.5066 2 21C2 24.0394 2.71368 26.912 3.98257 29.4597C4.31979 30.1367 4.43202 30.9106 4.23653 31.6411L3.10487 35.8707C2.61361 37.7067 4.29332 39.3863 6.12936 38.8952L10.3588 37.7635C11.0895 37.568 11.8633 37.6803 12.5403 38.0173C15.0879 39.2864 17.9606 40 21 40C31.4933 40 40 31.4933 40 21C40 17.5393 39.0747 14.2947 37.4582 11.5"
                stroke="white"
                strokeWidth="2.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <div onClick={() => setShowInfo(true)} className="me-svg">
            <svg
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.0104 20.7059C18.5305 20.7075 17.0834 20.2702 15.8522 19.4492C14.6209 18.6281 13.6608 17.4604 13.0934 16.0936C12.5259 14.7268 12.3766 13.2224 12.6644 11.7708C12.9521 10.3191 13.6639 8.98545 14.7098 7.93845C15.7557 6.89144 17.0886 6.17816 18.5399 5.88886C19.9913 5.59956 21.4958 5.74723 22.8632 6.31318C24.2306 6.87914 25.3994 7.83796 26.2217 9.06833C27.0441 10.2987 27.483 11.7453 27.483 13.2252C27.4809 15.2072 26.6932 17.1075 25.2925 18.5097C23.8918 19.9119 21.9924 20.7017 20.0104 20.7059ZM20.0104 8.18671C19.0116 8.1851 18.0348 8.47992 17.2036 9.03384C16.3725 9.58775 15.7244 10.3758 15.3414 11.2983C14.9584 12.2208 14.8578 13.2362 15.0523 14.2159C15.2467 15.1956 15.7275 16.0956 16.4338 16.8019C17.1401 17.5081 18.0401 17.9889 19.0198 18.1834C19.9995 18.3779 21.0149 18.2772 21.9373 17.8943C22.8598 17.5113 23.6479 16.8632 24.2018 16.032C24.7558 15.2009 25.0506 14.2241 25.049 13.2252C25.049 11.8889 24.5181 10.6074 23.5732 9.66246C22.6283 8.71755 21.3467 8.18671 20.0104 8.18671Z"
                fill="white"
              />
              <path
                d="M20.0104 40C15.5318 39.9958 11.1839 38.4903 7.66157 35.7241C7.53 35.6198 7.42111 35.4897 7.34159 35.3418C7.26206 35.1939 7.21357 35.0314 7.1991 34.8641C7.1991 34.7343 7.1991 34.6126 7.1991 34.4827C7.1991 32.7993 7.53068 31.1323 8.17492 29.577C8.81916 28.0216 9.76343 26.6084 10.9538 25.418C12.1442 24.2276 13.5574 23.2834 15.1128 22.6391C16.6681 21.9949 18.3351 21.6633 20.0185 21.6633C21.702 21.6633 23.369 21.9949 24.9243 22.6391C26.4797 23.2834 27.8929 24.2276 29.0833 25.418C30.2737 26.6084 31.2179 28.0216 31.8622 29.577C32.5064 31.1323 32.838 32.7993 32.838 34.4827C32.838 34.6126 32.838 34.7343 32.838 34.8641C32.8235 35.0314 32.775 35.1939 32.6955 35.3418C32.616 35.4897 32.5071 35.6198 32.3755 35.7241C28.8489 38.4937 24.4946 39.9994 20.0104 40ZM9.63317 34.1663C12.6426 36.3744 16.2778 37.5651 20.0104 37.5651C23.743 37.5651 27.3783 36.3744 30.3877 34.1663C30.2816 31.4851 29.1419 28.949 27.2074 27.0895C25.2729 25.23 22.6937 24.1914 20.0104 24.1914C17.3271 24.1914 14.748 25.23 12.8134 27.0895C10.8789 28.949 9.73922 31.4851 9.63317 34.1663Z"
                fill="white"
              />
              <path
                d="M20.0104 39.9999C15.2804 40.0024 10.7026 38.3284 7.09007 35.2752C3.4775 32.222 1.06394 27.9872 0.278041 23.323C-0.507857 18.6588 0.384768 13.867 2.79739 9.79856C5.21002 5.73015 8.98648 2.64849 13.4561 1.10087C17.9257 -0.446761 22.7992 -0.360185 27.211 1.34522C31.6229 3.05062 35.2875 6.26447 37.5541 10.416C39.8207 14.5675 40.5426 19.388 39.5915 24.0214C38.6404 28.6548 36.078 32.8011 32.3593 35.7241C28.837 38.4902 24.489 39.9957 20.0104 39.9999ZM20.0104 2.4503C16.3605 2.45047 12.8016 3.5886 9.82886 5.70625C6.85616 7.8239 4.61767 10.8157 3.42499 14.2652C2.23231 17.7147 2.1448 21.4502 3.17463 24.9517C4.20446 28.4533 6.30039 31.5466 9.17065 33.8011C11.7602 35.8334 14.8688 37.0967 18.1419 37.4471C21.4149 37.7974 24.7205 37.2205 27.6815 35.7824C30.6424 34.3442 33.1394 32.1026 34.8876 29.3134C36.6357 26.5242 37.5646 23.2998 37.5682 20.0081C37.5617 15.3534 35.7098 10.8913 32.4185 7.59996C29.1272 4.30864 24.665 2.45674 20.0104 2.4503Z"
                fill="white"
              />
            </svg>
          </div>
          {showInfo && (
            <>
              <div onClick={() => setShowInfo(false)} className="dark-bg"></div>
              <div className="info">
                <div className="avatar--name">
                  <div className="avatar">
                    <img src={me.avatar} alt="" />
                  </div>
                  <p className="name">{me.name}</p>
                </div>
                <div className="balance">
                  Баланс <span>{me.balance}</span>
                </div>
                <div
                  onClick={() => {
                    dispatch(setShowPayForm(true));
                    setShowInfo(false);
                  }}
                  className="replenish"
                >
                  Пополнить
                  <svg
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.213013 13.795C-0.0710058 13.5216 -0.0710057 13.0784 0.213013 12.805L6.24421 7L0.213014 1.19498C-0.0710047 0.921608 -0.0710046 0.478394 0.213014 0.205025C0.497031 -0.068341 0.957515 -0.0683409 1.24153 0.205025L7.78699 6.50503C8.07101 6.77839 8.07101 7.22161 7.78699 7.49498L1.24153 13.795C0.957514 14.0683 0.49703 14.0683 0.213013 13.795Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <div onClick={logout} className="logout">
                  Выйти
                  <svg
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.213013 13.795C-0.0710058 13.5216 -0.0710057 13.0784 0.213013 12.805L6.24421 7L0.213014 1.19498C-0.0710047 0.921608 -0.0710046 0.478394 0.213014 0.205025C0.497031 -0.068341 0.957515 -0.0683409 1.24153 0.205025L7.78699 6.50503C8.07101 6.77839 8.07101 7.22161 7.78699 7.49498L1.24153 13.795C0.957514 14.0683 0.49703 14.0683 0.213013 13.795Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <button
          onClick={() => dispatch(setShowLoginForm(true))}
          className="enter-button"
        >
          <p>Войти</p>
        </button>
      )}
    </header>
  );
}

export function BasePage({ children }: { children: ReactNode }) {
  const dispatch = useDispatch();
  const showChat = useSelector((state: InitialState) => state.showChat);
  const bgImage = useSelector((state: InitialState) => state.bgImage);

  useEffect(() => {
    dispatch(
      setBgImage({
        image: localStorage.getItem("backgroundImage"),
        aspectRatio: localStorage.getItem("backgroundImageAspectRatio")
          ? parseFloat(localStorage.getItem("backgroundImageAspectRatio")!)
          : null,
      })
    );

    const onResize = () => dispatch(setWinSize({width: window.innerWidth, height: window.innerHeight}))

    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);

  }, []);



  return (
    <>
      <div id="payment-form"></div>
      <div className="parent">
        {showChat && (
          <div
            style={{
              background: bgImage.image ? `url(${bgImage.image})` : undefined,
              // backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize:
                bgImage.aspectRatio &&
                (bgImage.aspectRatio > 1 || window.innerWidth < 900)
                  ? "cover"
                  : "auto",
            }}
            className="bg-photo"
          ></div>
        )}

        <Header />
        {children}
      </div>
    </>
  );
}
