import { useSelector } from "react-redux";
import { setMessages, setShowPayForm } from "../redux/actions";
import { TextAreaComponent } from "./TextareaComponent";
import { InitialState, MessageType } from "../redux/interfaces";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DOMPurify from "dompurify";
import hljs from "highlight.js";
import { MessageComponent } from "./MessageComponent";
import robotAvatar from "./robot-avatar.png";
import "./css/chat.css";
import "./css/message.css";
import "./css/codeBlock.css";
import "./css/panel.css";
import "./css/textarea.css";
import { ControlPanel } from "./ControlPanel";

export function Chat() {
  const me = useSelector((state: InitialState) => state.me);
  const messages = useSelector((state: InitialState) => state.messages);
  const needReplenishMessage = useSelector(
    (state: InitialState) => state.needReplenishMessage
  );
  const [currentGeneratingMessage, setCurrentGeneratingMessage] =
    useState<MessageType | null>(null);
  const dispatch = useDispatch();

  const applySyntaxHighlighting = () => {
    (document.querySelectorAll("pre code") as NodeListOf<HTMLElement>).forEach(
      (block) => {
        // Убедитесь, что блок не был уже подсвечен
        if (!block.dataset.highlighted) {
          const codeContent = block.innerHTML;

          // Очищаем содержимое с помощью DOMPurify
          const sanitizedContent = DOMPurify.sanitize(codeContent);

          // Устанавливаем очищенное содержимое
          block.innerHTML = sanitizedContent;

          // Подсвечиваем код с помощью highlight.js
          hljs.highlightElement(block);

          // Добавляем метку, чтобы избежать повторной подсветки
          block.dataset.highlighted = "true";
        }
      }
    );
  };
  useEffect(() => {
    applySyntaxHighlighting();
    const interval = setInterval(() => {
      applySyntaxHighlighting();
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const messagesHTML = document.querySelectorAll(".message");
    if (messagesHTML.length > 0) {
      const lastMessageHTML = messagesHTML[messagesHTML.length - 1];
      lastMessageHTML.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages.length]);

  useEffect(() => {
    if (currentGeneratingMessage) {
      const newMessages = [...messages];
      const lastMsg = newMessages[newMessages.length - 1];
      if (lastMsg.role === "assistant") {
        newMessages[newMessages.length - 1] = currentGeneratingMessage;
      } else {
        newMessages.push(currentGeneratingMessage);
      }
      dispatch(setMessages(newMessages));
    }
  }, [currentGeneratingMessage]);

  useEffect(() => {
    const chatPageHTML = document.querySelector(".chat-page");
    if (chatPageHTML) {
      chatPageHTML.scrollTop = chatPageHTML.scrollHeight;
    }
    const onResize = () => {
      const chat = document.querySelector(".chat-parent") as HTMLElement;
      const textareaBox = document.querySelector(
        ".chat-input-box"
      ) as HTMLElement;
      if (chat && textareaBox) {
        if (window.innerWidth < 1050) {
          textareaBox.style.width = "100%";
        } else {
          textareaBox.style.width = chat.offsetWidth + "px";
        }
      }
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  useEffect(() => {
    if (needReplenishMessage) {
      const msgs = document.querySelectorAll(".message");
      if (msgs.length > 0) {
        msgs[msgs.length - 1].scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [needReplenishMessage]);

  useEffect(() => {
    const scrollTo = document.getElementById("scroll-to");
    if (scrollTo) {
      scrollTo.scrollIntoView({ behavior: "smooth" });
    }
  }, []);


  return (
    <>
      <div className={`chat-page`}>
        <ControlPanel />
        <div className="chat-parent">
          {messages.map((message, index) => (
            <div key={index} className="message">
              <div className="avatar">
                <img
                  src={message.role === "user" ? me?.avatar : robotAvatar}
                  alt=""
                />
              </div>
              <div className="text">
                {message.role === "user" ? (
                  message.text
                ) : (
                  <MessageComponent message={message} />
                )}
              </div>
            </div>
          ))}
          {needReplenishMessage && (
            <div className="message">
              <div className="avatar">
                <img src={robotAvatar} alt="" />
              </div>
              <div className="text">{needReplenishMessage}</div>
              <button
                onClick={() => dispatch(setShowPayForm(true))}
                className="replenish"
              >
                Пополнить
              </button>
            </div>
          )}
          <div
            style={{ height: 0, margin: 0 }}
            id="scroll-to"
            className="message"
          ></div>
        </div>
        <div className="margin-block"></div>
        <TextAreaComponent
          currentGeneratingMessage={currentGeneratingMessage}
          setCurrentGeneratingMessage={setCurrentGeneratingMessage}
        />
      </div>
    </>
  );
}
