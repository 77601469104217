import { BasePage } from "../base/BasePage";
import { useEffect, useRef, useState } from "react";
import "./css/main.css";
import "./css/skills.css";
import "./css/tariffs.css";
import "./css/flipClock.css";
import "./css/questions.css";
import { useDispatch } from "react-redux";
import { setShowChat, setShowLoginForm } from "../redux/actions";
import { useSelector } from "react-redux";
import { InitialState } from "../redux/interfaces";
import { DOMAIN } from "../App";
import axios from "axios";

export function Main() {
  const dispatch = useDispatch();

  return (
    <div className="main-parent">
      <div className="text-box">
        <h6>Чат</h6>
        <p>GPT-Photo</p>
        <h6>—</h6>
      </div>
      <h6> поможет Вам в решении различных задач </h6>
      <div className="button-registration">
        <div className="button-registration-box">
          <button>
            <p onClick={() => dispatch(setShowLoginForm(true))}>
              Зарегистрироваться
            </p>
          </button>
          <p>Быстрая регистрация через вк или telegram</p>
        </div>
      </div>
    </div>
  );
}
export function Skills() {
  return (
    <div className="skills-parent">
      <h5>Что может робот ?</h5>
      <div className="description">
        <p>
          Основным преимуществом GPT-Photo является его способность распознавать
          текст на изображениях, что позволяет сэкономить ваше время, так как
          нет необходимости вводить запросы вручную.
        </p>
      </div>
      <div className="skills">
        <div className="skill-box-1 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-1 wh">
            <div className="text">
              <h1>Решение домашнего задания</h1>
            </div>
            <div className="line"></div>
            <p>
              Может помочь с решением задач по различным предметам и дисциплинам
            </p>
          </div>
        </div>
        <div className="skill-box-2 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-2 wh">
            <div className="text">
              <h1>Написание кода</h1>
            </div>

            <div className="line"></div>
            <p>
              Может писать на различных языках программирования по заданным
              требованиям
            </p>
          </div>
        </div>
        <div className="skill-box-3 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-3 wh">
            <div className="text">
              <h1>Составление тестов</h1>
            </div>

            <div className="line"></div>
            <p>
              Может составлять тестовые задания<br></br> и вопросы по разным
              темам для проверки знаний
            </p>
          </div>
        </div>
        <div className="skill-box-4 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-4 wh">
            <div className="text">
              <h1>Помощь в обучении</h1>
            </div>

            <div className="line"></div>
            <p>
              Может помогать с пониманием материала и поддерживать учебный
              процесс.
            </p>
          </div>
        </div>
        <div className="skill-box-5 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-5 wh">
            <div className="text">
              <h1>Доработка текста</h1>
            </div>

            <div className="line"></div>
            <p>
              Может редактировать, исправлять и улучшать тексты <br></br> для
              лучшего понимания
            </p>
          </div>
        </div>
        <div className="skill-box-6 skill-box">
          <div className="skill-border wh border"></div>
          <div className="skill-6 wh">
            <div className="text">
              <h1>Генерация идей</h1>
            </div>

            <div className="line"></div>
            <p>
              Может предложить новые концепции или подходы для решения задач и
              создания контента.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export function Tariffs() {
  const pluses = [
    "Нет ограничений на распознование текста с фото",
    "Нет ограничений на запросы",
    "Быстрая скорость ответов",
    "Моментальное распознование текста",
  ];
  const me = useSelector((state: InitialState) => state.me);
  const winSize = useSelector((state: InitialState) => state.winSize);
  const dispatch = useDispatch();
  const onTry = (e: any) => {
    console.log(e.target);
    if (me) {
      dispatch(setShowChat(true));
    } else {
      dispatch(setShowLoginForm(true));
    }
  };
  return (
    <div className="tariffs-parent">
      <h5>Тарифы</h5>
      <div className="description">
        {winSize.width < 800 || window.innerWidth < 800 ? (
          <p>
            Наши условия отличаются от подхода других компаний. У нас
            предусмотрен всего один тарифный план, который доступен каждому. Вы
            можете вносить на счет сколько угодно, в зависимости от ваших
            потребностей и возможностей..
          </p>
        ) : (
          <p>
            Наши условия отличаются от подхода других компаний. У нас
            предусмотрен всего один тарифный план, который доступен каждому. Вам
            не нужно беспокоиться о выборе из множества вариантов, так как наш
            единственный тариф разработан так, чтобы удовлетворить потребности
            всех наших клиентов. Кроме того, у нас нет строгих ограничений на
            сумму, которую вы можете внести на свой счет. Вы можете вносить на
            счет сколько угодно, в зависимости от ваших потребностей и
            возможностей.
          </p>
        )}
      </div>
      <div className="tariff-box-parent">
        <div className="tariff-box">
          <div className="tariff-border"></div>
          <div className="tariff">
            <div className="circle-1"></div>
            <div className="circle-2"></div>
            <div className="circle-3"></div>
            <div className="circle-4"></div>
            <div className="pluses">
              {pluses.map((plus, index) => (
                <div key={index} className="plus">
                  <div>
                    <div className="marker"></div>
                  </div>
                  <p>{plus}</p>
                </div>
              ))}
            </div>
            <div className="button-box">
              <div>
                <p>1 запрос = 2 рубль</p>
                <div className="test-button-box">
                  <div className="test-button-border"></div>
                  <div className="test-button">
                    <button onClick={onTry}>
                      <p>Попробовать</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blur"></div>
        </div>
      </div>
    </div>
  );
}
function FlipClock() {
  const [value, setValue] = useState("0");
  const [nums, setNums] = useState([
    {
      num: "0",
      anim: false,
    },
    {
      num: "0",
      anim: false,
    },
    {
      num: "0",
      anim: false,
    },
    {
      num: "0",
      anim: false,
    },
    {
      num: "0",
      anim: false,
    },
    {
      num: "0",
      anim: false,
    },
  ]);

  useEffect(() => {
    axios.get(DOMAIN + "api/users/query-count/").then((resp) => {
      setValue(String(resp.data.count));
      handleAdd(String(resp.data.count));
    });
  }, []);

  useEffect(() => {
    if (value !== "0") {
      const interval = setInterval(() => {
        axios.get(DOMAIN + "api/users/query-count/").then((resp) => {
          if (resp.data.count > value) {
            setValue(String(resp.data.count));
            handleAdd(String(resp.data.count));
          }
        });
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [nums]);

  const addValue = (val: string) => {
    let newValue = val;

    while (newValue.length < 6) {
      newValue = "0" + newValue;
    }

    return newValue;
  };

  const handleAdd = (val: string) => {
    addValue(val);
    setNums((prev) =>
      addValue(val)
        .split("")
        .map((n, index) => {
          return {
            num: prev[index].num,
            anim: Number(prev[index].num) !== Number(n),
          };
        })
    );
    setTimeout(() => {
      setNums((prev) =>
        prev.map((n) => {
          return { num: n.num, anim: false };
        })
      );
    }, 1500);
    setTimeout(() => {
      setNums((prev) =>
        prev.map((n, index) => {
          return {
            num: String(addValue(val).split("")[index]),
            anim: n.anim,
          };
        })
      );
    }, 450);
  };

  return (
    <div className="flip-clock">
      <h1>Число наших клиентов растет!</h1>
      <div className="nums">
        <div className="bg-blur">
          <div className="blur"></div>
        </div>
        <div className="line"></div>
        {nums.map((n, index) => (
          <div
            key={index}
            className={`num-parent ${n.anim ? "animating" : ""}`}
          >
            <div className="num-border"></div>
            <div className="anim-num num">
              <p>{n.num}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
function QuestionsComponent() {
  const [questions, setQuestions] = useState([
    {
      title:
        window.innerWidth > 600
          ? "Как пополнить баланс и какая минимальная сумма?"
          : "Как пополнить баланс?",
      text: `Чтобы пополнить баланс на нашем сайте, сначала зарегистрируйтесь. После регистрации нажмите на иконку "аватар" в правом верхнем углу экрана, где вы найдете кнопку "Пополнить". Вы можете выбрать удобный для вас способ оплаты, включая банковские карты, электронные кошельки и мобильные платежи. Минимальная сумма пополнения составляет всего 50 рублей.`,
      full: false,
    },
    {
      title:
        window.innerWidth > 600
          ? "Сколько стоит один запрос к GPT-роботу?"
          : "Сколько стоит один запрос?",
      text: "Один запрос к нашему GPT-роботу стоит 2 рубля. Вы можете использовать пополненный баланс для отправки запросов. Стоимость запроса будет автоматически списываться с вашего баланса при каждом обращении к роботу.",
      full: false,
    },
    {
      title:
        window.innerWidth > 600
          ? "Как использовать распознавание текста с фото?"
          : "Как использовать распознавание?",
      text: "Наш GPT-робот обладает возможностью распознавать текст с изображений. Чтобы воспользоваться этой функцией, загрузите фото с текстом, нажав на соответствующую кнопку слева от поля ввода текста в чате с роботом. Робот быстро обработает изображение и предоставит вам распознанный текст. Стоимость этой услуги составляет 2 рубля за один запрос.",
      full: false,
    },
    {
      title:
        window.innerWidth > 600
          ? "Как присоединиться к сообществу ВК и TG?"
          : "Как присоединиться к сообществам?",
      full: false,
      text: `Чтобы присоединиться к нашему сообществу в социальных сетях, найдите нас на ВКонтакте и Telegram. В ВКонтакте вы можете найти нашу группу по названию и подписаться на неё. Ссылку на сообщество можно найти внизу нашего сайта, нажав на соответствующую иконку. Для присоединения к группе в Telegram, просто перейдите по ссылке на нашем сайте или найдите нашу группу, введя в поиск "Neosama" в приложении Telegram. Мы будем рады вашему участию!`,
    },
    {
      title:
        window.innerWidth > 600
          ? "Что делать, если возникли проблемы с сайтом?"
          : "Что делать, если возникли проблемы?",
      full: false,
      text: `Если у вас возникли проблемы с использованием нашего сайта или GPT-робота, пожалуйста, обратитесь в службу поддержки. Вы можете связаться с нами, отправив сообщение на электронную почту службы поддержки, или написать в наше сообщество ВКонтакте или группу в Telegram. Мы постараемся помочь вам в кратчайшие сроки.`,
    },
    {
      title:
        window.innerWidth > 600
          ? "Какие данные необходимы для регистрации?"
          : "Какие данные нужны для регистрации?",
      text: `Для регистрации на нашем сайте вам потребуется аккаунт ВКонтакте или Telegram. Это удобный и быстрый способ создания учетной записи. Мы не запрашиваем дополнительных личных данных, обеспечивая простоту и безопасность процесса регистрации.`,
      full: false,
    },
    {
      text: `В настоящее время у нас нет жёстких ограничений по количеству запросов в день. Однако, мы рекомендуем использовать сервис ответственно, чтобы обеспечить справедливый доступ всем пользователям. В случае подозрения на злоупотребление, мы оставляем за собой право ограничить доступ к сервису.`,
      title:
        window.innerWidth > 600
          ? "Есть ли ограничения по количеству запросов в день?"
          : "Есть ли ограничения по запросам в день?",
      full: false,
    },
  ]);
  const onFull = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      full: !newQuestions[index].full,
    };
    setQuestions(newQuestions);
  };
  return (
    <div className="questions-parent">
      <h1>Часто задаваемые вопросы</h1>
      <div className="circles-bg">
        <div className="circle-1"></div>
        <div className="circle-2"></div>
        <div className="circle-3"></div>
        <div className="questions">
          {questions.map((q, index) => (
            <div
              onClick={() => onFull(index)}
              key={index}
              className={`question-parent ${q.full ? "full" : ""}`}
            >
              <div className="question-border"></div>
              <div className="question">
                <div className="visible">
                  <p>{q.title}</p>
                  <div className="arrow">
                    <svg
                      viewBox="0 0 27 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M1.36612 1.37277C1.85427 0.875742 2.64573 0.875742 3.13388 1.37277L13.5 11.9274L23.8661 1.37277C24.3543 0.875742 25.1457 0.875742 25.6339 1.37277C26.122 1.8698 26.122 2.67565 25.6339 3.17268L14.3839 14.6272C13.8957 15.1243 13.1043 15.1243 12.6161 14.6272L1.36612 3.17268C0.877961 2.67565 0.877961 1.8698 1.36612 1.37277Z"
                        fill="white"
                        stroke="url(#paint0_linear_126_157)"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_126_157"
                          x1="-4.68524"
                          y1="16.0388"
                          x2="0.253534"
                          y2="-7.30901"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="white" stop-opacity="0.91" />
                          <stop offset="0.515" stop-opacity="0" />
                          <stop offset="0.973778" stop-color="white" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="text">
                  <p>{q.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export function RegistForm() {
  const dispatch = useDispatch();
  const [uc, setUC] = useState(false);
  const onClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((e.target as HTMLElement).classList.contains("dark-bg"))
      dispatch(setShowLoginForm(false));
  };
  const onClickLogo = (name: string) => {
    if (!uc) {
      return false;
    }
    axios.post(DOMAIN + `api/users/${name}_login/`).then((resp) => {
      window.location.href = resp.data.redirect_url;
    });
  };
  return (
    <div onClick={onClose} className={`dark-bg`}>
      <div className="regist">
        <h1>Войти через:</h1>
        <div className="icons">
          <svg
            onClick={() => onClickLogo("vk")}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0C8.95417 0 0 8.95417 0 20C0 31.0458 8.95417 40 20 40C31.0458 40 40 31.0458 40 20C40 8.95417 31.0458 0 20 0ZM27.324 22.2166C27.5663 22.4502 27.8118 22.6823 28.0465 22.9235C28.5738 23.4653 29.5381 24.4913 29.8732 25.08C29.8889 25.1076 29.9046 25.1341 29.9187 25.1625C30.0958 25.4604 30.1375 25.6917 30.05 25.8646C29.9042 26.1521 29.4042 26.2938 29.2333 26.3063H26.1083C25.8917 26.3063 25.4375 26.25 24.8875 25.8708C24.4646 25.575 24.0479 25.0896 23.6417 24.6167C23.0354 23.9125 22.5104 23.3042 21.9813 23.3042C21.914 23.3041 21.8472 23.3146 21.7833 23.3354C21.3833 23.4646 20.8708 24.0354 20.8708 25.5562C20.8708 26.0312 20.4958 26.3042 20.2313 26.3042H18.8C18.3125 26.3042 15.7729 26.1333 13.5229 23.7604C10.7687 20.8542 8.28958 15.025 8.26875 14.9708C8.1125 14.5937 8.43542 14.3917 8.7875 14.3917H11.9437C12.3646 14.3917 12.5021 14.6479 12.5979 14.875C12.7104 15.1396 13.1229 16.1917 13.8 17.375C14.8979 19.3042 15.5708 20.0875 16.1104 20.0875C16.2116 20.0863 16.311 20.0606 16.4 20.0125C17.1042 19.6208 16.9729 17.1104 16.9417 16.5896C16.9417 16.4917 16.9396 15.4667 16.5792 14.975C16.4583 14.8083 16.2978 14.69 16.1339 14.6062C15.9067 14.4899 15.801 14.1956 16.0292 14.0812C16.5125 13.8396 17.3833 13.8042 18.2479 13.8042H18.7292C19.6667 13.8167 19.9083 13.8771 20.2479 13.9625C20.9354 14.1271 20.95 14.5708 20.8896 16.0896C20.8708 16.5208 20.8521 17.0083 20.8521 17.5833C20.8521 17.7083 20.8458 17.8417 20.8458 17.9833C20.825 18.7562 20.8 19.6333 21.3458 19.9937C21.417 20.0384 21.4993 20.0622 21.5833 20.0625C21.7729 20.0625 22.3438 20.0625 23.8896 17.4104C24.306 16.6648 24.6748 15.8936 24.9937 15.1016C25.086 14.8726 25.1483 14.5973 25.3604 14.4708C25.4404 14.4301 25.529 14.4093 25.6187 14.4104H29.3292C29.7333 14.4104 30.0104 14.4708 30.0625 14.6271C30.1542 14.875 30.0458 15.6312 28.3521 17.925L27.5958 18.9229C26.186 20.7708 26.0707 21.008 27.324 22.2166Z"
              fill="white"
            />
          </svg>

          <svg
            onClick={() => onClickLogo("telegram")}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 0C8.955 0 0 8.955 0 20C0 31.045 8.955 40 20 40C31.045 40 40 31.045 40 20C40 8.955 31.045 0 20 0ZM29.8233 13.7017L26.54 29.1683C26.2983 30.265 25.645 30.5317 24.7333 30.015L19.7333 26.3317L17.3233 28.655C17.09 28.955 16.7283 29.1467 16.3233 29.1467C16.32 29.1467 16.3183 29.1467 16.315 29.1467L16.67 24.0567L25.9367 15.6867C26.3367 15.3317 25.8467 15.13 25.315 15.485L13.8667 22.695L8.93333 21.155C7.86667 20.8167 7.83667 20.0883 9.15833 19.565L28.435 12.135C29.3317 11.8083 30.1117 12.3483 29.8217 13.7033L29.8233 13.7017Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="user-confirmation">
          <input type="checkbox" onChange={(e) => setUC(e.target.checked)} />
          <a href={`${DOMAIN}uc.pdf`}>Пользовательское соглашение</a>
        </div>
      </div>
    </div>
  );
}

export function Home() {
  const showChat = useSelector((state: InitialState) => state.showChat);
  if (showChat) return null;
  return (
    <>
      <div className={`home-page`}>
        <Main />
        <Skills />
        <Tariffs />
        <FlipClock />
        <QuestionsComponent />
      </div>
      <footer>
        <div className="social">
          <a href="https://vk.com/club226299301" target="blank">
            <svg
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0C8.95417 0 0 8.95417 0 20C0 31.0458 8.95417 40 20 40C31.0458 40 40 31.0458 40 20C40 8.95417 31.0458 0 20 0ZM27.324 22.2166C27.5663 22.4502 27.8118 22.6823 28.0465 22.9235C28.5738 23.4653 29.5381 24.4913 29.8732 25.08C29.8889 25.1076 29.9046 25.1341 29.9187 25.1625C30.0958 25.4604 30.1375 25.6917 30.05 25.8646C29.9042 26.1521 29.4042 26.2938 29.2333 26.3063H26.1083C25.8917 26.3063 25.4375 26.25 24.8875 25.8708C24.4646 25.575 24.0479 25.0896 23.6417 24.6167C23.0354 23.9125 22.5104 23.3042 21.9813 23.3042C21.914 23.3041 21.8472 23.3146 21.7833 23.3354C21.3833 23.4646 20.8708 24.0354 20.8708 25.5562C20.8708 26.0312 20.4958 26.3042 20.2313 26.3042H18.8C18.3125 26.3042 15.7729 26.1333 13.5229 23.7604C10.7687 20.8542 8.28958 15.025 8.26875 14.9708C8.1125 14.5937 8.43542 14.3917 8.7875 14.3917H11.9437C12.3646 14.3917 12.5021 14.6479 12.5979 14.875C12.7104 15.1396 13.1229 16.1917 13.8 17.375C14.8979 19.3042 15.5708 20.0875 16.1104 20.0875C16.2116 20.0863 16.311 20.0606 16.4 20.0125C17.1042 19.6208 16.9729 17.1104 16.9417 16.5896C16.9417 16.4917 16.9396 15.4667 16.5792 14.975C16.4583 14.8083 16.2978 14.69 16.1339 14.6062C15.9067 14.4899 15.801 14.1956 16.0292 14.0812C16.5125 13.8396 17.3833 13.8042 18.2479 13.8042H18.7292C19.6667 13.8167 19.9083 13.8771 20.2479 13.9625C20.9354 14.1271 20.95 14.5708 20.8896 16.0896C20.8708 16.5208 20.8521 17.0083 20.8521 17.5833C20.8521 17.7083 20.8458 17.8417 20.8458 17.9833C20.825 18.7562 20.8 19.6333 21.3458 19.9937C21.417 20.0384 21.4993 20.0622 21.5833 20.0625C21.7729 20.0625 22.3438 20.0625 23.8896 17.4104C24.306 16.6648 24.6748 15.8936 24.9937 15.1016C25.086 14.8726 25.1483 14.5973 25.3604 14.4708C25.4404 14.4301 25.529 14.4093 25.6187 14.4104H29.3292C29.7333 14.4104 30.0104 14.4708 30.0625 14.6271C30.1542 14.875 30.0458 15.6312 28.3521 17.925L27.5958 18.9229C26.186 20.7708 26.0707 21.008 27.324 22.2166Z"
                fill="white"
              />
            </svg>
          </a>
          <a href="https://t.me/neosamagroup" target="blank">
            <svg
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 0C8.955 0 0 8.955 0 20C0 31.045 8.955 40 20 40C31.045 40 40 31.045 40 20C40 8.955 31.045 0 20 0ZM29.8233 13.7017L26.54 29.1683C26.2983 30.265 25.645 30.5317 24.7333 30.015L19.7333 26.3317L17.3233 28.655C17.09 28.955 16.7283 29.1467 16.3233 29.1467C16.32 29.1467 16.3183 29.1467 16.315 29.1467L16.67 24.0567L25.9367 15.6867C26.3367 15.3317 25.8467 15.13 25.315 15.485L13.8667 22.695L8.93333 21.155C7.86667 20.8167 7.83667 20.0883 9.15833 19.565L28.435 12.135C29.3317 11.8083 30.1117 12.3483 29.8217 13.7033L29.8233 13.7017Z"
                fill="white"
              />
            </svg>
          </a>

          <p className="email">gpt-photo@neosama.ru</p>
        </div>
        <div className="company">
          <a href="https://neosama.ru">© 2024 «NeoSama»</a>
          <br />
          Все права защищены
        </div>
      </footer>
    </>
  );
}
