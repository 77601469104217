import axios from "axios";
import { useEffect } from "react";
import { DOMAIN } from "../App";
import { useParams } from "react-router-dom";

export function SocLogin({ name }: { name: string }) {
  const params = useParams();
  console.log(params);
  let link = window.location.href;
  console.log(link.split("=").pop());
  useEffect(() => {
    let data: any = {
      tgAuthResult: link.split("=").pop(),
    };
    if (name === "vk") {
      let code = link.split("=").pop();
      console.log(code);
      data = {
        code: code,
      };
    }
    axios.post(DOMAIN + `api/users/${name}_callback/`, data).then(() => {
      window.location.href = "/";
    });
  }, []);
  return null;
}
