import { useEffect, useRef, useState } from "react";
import { InitialState, MessageType } from "../redux/interfaces";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import axios from "axios";
import { DOMAIN } from "../App";
import { setIsKeyboardOpen, setMe, setMessages, setNeedReplenishMessage } from "../redux/actions";
import { ControlPanel } from "./ControlPanel";
import "./css/chat.css";
import "./css/message.css";
import "./css/codeBlock.css";
import "./css/panel.css";
import "./css/textarea.css";

interface TextAreaComponentProps {
  setCurrentGeneratingMessage: (message: MessageType) => void;
  currentGeneratingMessage: MessageType | null;
}

export const TextAreaComponent: React.FC<TextAreaComponentProps> = ({
  setCurrentGeneratingMessage,
  currentGeneratingMessage,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [height, setHeight] = useState(21);
  const [recognizeImageFetching, setRegocnizeImageFetching] = useState(false);
  const [dots, setDots] = useState<number>(1);
  const dispatch = useDispatch();
  const messages = useSelector((state: InitialState) => state.messages);
  const me = useSelector((state: InitialState) => state.me);

  const adjustTextareaHeight = () => {
    const textarea = textAreaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
      textarea.style.overflowY =
        textarea.scrollHeight > 240 ? "scroll" : "hidden";
      const scrollTo = document.getElementById("scroll-to");
      if (scrollTo) {
        const height = textarea.scrollHeight > 240 ? 240 : textarea.scrollHeight;
        (scrollTo as HTMLElement).style.marginTop = `${height}px`;
        scrollTo.scrollIntoView({ behavior: "smooth" });
        const mobilePanelTrigger = document.querySelector(
          ".icons-mobile-toggler-parent"
        ) as HTMLElement;
        const controlPanel = document.querySelector(
          ".control-panel-parent"
        ) as HTMLElement;
        if (mobilePanelTrigger && controlPanel) {
          mobilePanelTrigger.style.bottom = `calc(${
            height + 28
          }px + 6 * (var(--resize-calc)))`;
          controlPanel.style.bottom = `${height - 20}px`;
        }
      }
      
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [height]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHeight(e.target.scrollHeight);
    adjustTextareaHeight();
  };

  const recognizeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;

    if (file) {
      setRegocnizeImageFetching(true);
      const formData = new FormData();
      formData.append("image", file);
      axios
        .post(`${DOMAIN}api/chat/recognize-image/`, formData, {
          responseType: "formdata",
        })
        .then((response) => {
          console.log("Image recognized successfully");
          console.log(response.data);
          if (textAreaRef.current) {
            setRegocnizeImageFetching(false);
            textAreaRef.current.value += JSON.parse(
              response.data
            ).recognized_text;

            setHeight(textAreaRef.current.scrollHeight);
            adjustTextareaHeight();
            if (me)
              dispatch(setMe({ ...me, balance: Math.max(me?.balance - 2, 0) }));
          }
        })
        .catch((error) => {
          setRegocnizeImageFetching(false);
          console.log(error);
          console.log(error.response && error.response.status === 403);
          console.log(error.response.data.detail);
          if (error.response && error.response.status === 403) {
            dispatch(
              setNeedReplenishMessage(JSON.parse(error.response.data).detail)
            );
          } else {
            console.error("Error recognizing image:", error);
          }
        });
    }
  };

  useEffect(() => {
    if (recognizeImageFetching) {
      const interval = setInterval(() => {
        setDots((prev) => {
          let res = prev + 1;
          if (res > 3) {
            res = 0;
          }
          return res;
        });
      }, 300);
      return () => clearInterval(interval);
    }
  }, [recognizeImageFetching]);

  const fetchMessage = async (value: string) => {
    try {
      const formData = new FormData();
      formData.append("message", value);
      const response = await fetch(DOMAIN + "api/chat/gpt-generation/", {
        body: formData,
        method: "POST",
      });
      const reader = response!.body!.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        let boundary = buffer.indexOf("}{");
        while (boundary !== -1) {
          const completeJSON = buffer.slice(0, boundary + 1);
          buffer = buffer.slice(boundary + 1);

          try {
            const line_json = JSON.parse(completeJSON);
            let message = line_json.message;
            if (!line_json.detail) {
              setTimeout(() => {
                setCurrentGeneratingMessage({
                  text: message,
                  role: "assistant",
                });
              }, 0);
            }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }

          boundary = buffer.indexOf("}{");
        }
      }
      if (buffer.trim()) {
        try {
          const line_json = JSON.parse(buffer);
          console.log(line_json);
          let message = line_json.message;
          if (line_json.detail) {
            dispatch(setNeedReplenishMessage(line_json.detail));
          } else {
            setTimeout(() => {
              setCurrentGeneratingMessage({
                text: message,
                role: "assistant",
              });
              if (me)
                dispatch(
                  setMe({ ...me, balance: Math.max(me?.balance - 2, 0) })
                );
            }, 0);
          }
        } catch (error) {
          console.error("Error parsing remaining JSON:", error);
        }
      }
    } catch (error) {
      console.log("Error fetching message:", error);
    }
  };

  const onSendMessage = async () => {
    if (textAreaRef!.current!.value !== "") {
      dispatch(
        setMessages([
          ...messages,
          { role: "user", text: textAreaRef!.current!.value },
        ])
      );
      const value = textAreaRef!.current!.value;
      textAreaRef!.current!.value = "";
      setHeight(textAreaRef!.current!.scrollHeight);
      adjustTextareaHeight();
      fetchMessage(value);
    }
  };

  const handleTab = (e: any) => {
    e.preventDefault();
    e.target.value += "    ";
  };

  return (
    <div className="chat-input-box-parent">
      <div className="chat-input-box">
        <div className="chat-input-parent">
          <div className="chat-input-border"></div>
          <div className="chat-input">
            <label className="send-img">
              <input
                accept="image/*"
                hidden
                type="file"
                onChange={recognizeImage}
              />
              <svg
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.13727 15.2654C10.5451 15.2654 11.6863 14.0776 11.6863 12.6123C11.6863 11.147 10.5451 9.95923 9.13727 9.95923C7.72949 9.95923 6.58825 11.147 6.58825 12.6123C6.58825 14.0776 7.72949 15.2654 9.13727 15.2654Z"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.60784 27.9999C14.5965 15.1678 20.4057 12.9123 26.9804 21.2467"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.0889 5.18359H6.99608C5.67103 5.18359 4.40027 5.7177 3.46331 6.66857C2.52636 7.61943 2 8.90916 2 10.2539V22.9296C2 24.2744 2.52636 25.5639 3.46331 26.5148C4.40027 27.4656 5.67103 27.9999 6.99608 27.9999H21.9843C23.3094 27.9999 24.5802 27.4656 25.517 26.5148C26.4541 25.5639 26.9804 24.2744 26.9804 22.9296V16.5918"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M23.9216 12.6122V2"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M19.8431 6.2449L23.9216 2L28 6.2449"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </label>
            <div className="textarea-parent">
              {recognizeImageFetching && (
                <div className="recognize-fetching">
                  Идет распознавание{".".repeat(dots)}
                </div>
              )}
              <textarea
                onKeyDown={(e) => {
                  if (e.key === "Tab") {
                    handleTab(e);
                  }
                }}
                onKeyPress={(e) => {
                  if (window.innerWidth > 1100) {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      onSendMessage();
                    }
                  }
                }}
                style={{
                  visibility: recognizeImageFetching ? "hidden" : "visible",
                }}
                ref={textAreaRef}
                onChange={handleChange}
                rows={1}
                placeholder="Введите сообщение"
              ></textarea>
            </div>
            <div className="send-message" onClick={onSendMessage}>
              <svg
                viewBox="0 0 23 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.5 2V24M11.5 2L21 11.4286M11.5 2L2 11.4286"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
