import React, { useEffect } from "react";
import logo from "./logo.svg";
import { Home, RegistForm } from "./home/Home";
import "./css/vars.css";
import "./css/style.css";

import { BasePage, PayForm } from "./base/BasePage";
import { useSelector } from "react-redux";
import { InitialState } from "./redux/interfaces";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { SocLogin } from "./base/SocLogin";
import axios from "axios";
import { setMe, setMessages, setShowChat } from "./redux/actions";
import { useDispatch } from "react-redux";
import { Chat } from "./chat/ChatComponent";
export const DOMAIN = "https://gpt-photo.ru/";
// export const DOMAIN = "http://127.0.0.1:8080/";
function App() {
  const showChat = useSelector((state: InitialState) => state.showChat);
  const showPayForm = useSelector((state: InitialState) => state.showPayForm);
  const showLoginForm = useSelector(
    (state: InitialState) => state.showLoginForm
  );
  const dispatch = useDispatch();
  useEffect(() => {
    axios
      .get(DOMAIN + "api/users/me/")
      .then((resp) => {
        console.log(resp.data);
        dispatch(setMe(resp.data));
        axios
          .get(DOMAIN + "api/chat/messages/")
          .then((resp) => {
            console.log(resp.data);
            dispatch(setMessages(resp.data));
            dispatch(setShowChat(true));
            window.localStorage.setItem("showChat", "1");
          })
          .catch((error) => {
            dispatch(setShowChat(false));
            window.localStorage.setItem("showChat", "0");
            console.log(error);
          });
      })
      .catch((error) => {
        dispatch(setShowChat(false));
        window.localStorage.setItem("showChat", "0");
        console.log(error);
      });
  }, []);

  return (
    <Router>
      <Routes>
        {["telegram", "vk"].map((soc) => (
          <Route
            key={`${soc}_callback`}
            path={`/api/users/${soc}_callback/`}
            element={<SocLogin name={soc} />}
          />
        ))}
        <Route
          path={`/`}
          element={
            <BasePage>
              {showChat ? <Chat /> : <Home />}
              {showLoginForm && <RegistForm />}
              {showPayForm && <PayForm />}
            </BasePage>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
