import { ActionType } from "./actions";
import { InitialState } from "./interfaces";

const initialState: InitialState = {
  needReplenishMessage: "",
  showChat: window.localStorage.getItem("showChat") === "1",
  showPayForm: false,
  showLoginForm: false,
  me: null,
  messages: [],
  bgImage: {
    image: localStorage.getItem("backgroundImage"),
    aspectRatio: localStorage.getItem("backgroundImageAspectRatio")
      ? parseFloat(localStorage.getItem("backgroundImageAspectRatio")!)
      : null,
  },
  copyMode: false,
  winSize: { width: window.innerWidth, height: window.innerHeight },
  isKeyboardOpen: false,
};

const rootReducer = (
  state: InitialState = initialState,
  action: ActionType
) => {
  switch (action.type) {
    case "setShowLoginForm":
      return {
        ...state,
        showLoginForm: action.data.show,
      };
    case "setShowChat":
      return {
        ...state,
        showChat: action.data.show,
      };
    case "setMe":
      return {
        ...state,
        me: action.data.me,
      };
    case "setMessages":
      return {
        ...state,
        messages: action.data.messages,
      };
    case "setBgImage":
      return {
        ...state,
        bgImage: action.data.bgImage,
      };
    case "setCopyMode":
      return {
        ...state,
        copyMode: action.data.copyMode,
      };
    case "setShowPayForm":
      return {
        ...state,
        showPayForm: action.data.showPayForm,
      };
    case "setNeedReplenishMessage":
      return {
        ...state,
        needReplenishMessage: action.data.needReplenishMessage,
      };
    case "setWinSize":
      return {
        ...state,
        winSize: action.data.winSize,
      };
      case "setIsKeyboardOpen":
        return {
          ...state,
          isKeyboardOpen: action.data.isKeyboardOpen,
        };
    default:
      return state;
  }
};

export default rootReducer;
