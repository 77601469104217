import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { InitialState } from "../redux/interfaces";
import { setBgImage, setCopyMode, setMessages } from "../redux/actions";
import axios from "axios";
import { DOMAIN } from "../App";
import "./css/chat.css";
import "./css/message.css";
import "./css/codeBlock.css";
import "./css/panel.css";
import "./css/textarea.css";

export function ControlPanel() {
  const [showControlPanel, setShowControlPanel] = useState(false);
  const bgImage = useSelector((state: InitialState) => state.bgImage);
  const dispatch = useDispatch();

  const copiedTextRef = useRef<string>(""); // Ссылка для накопления выделенного текста
  const copyMode = useSelector((state: InitialState) => state.copyMode);

  useEffect(() => {
    const handleMouseUp = () => {
      if (copyMode) {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
          const range = selection.getRangeAt(0);
          const selectedText = range.toString();

          if (selectedText) {
            const span = document.createElement("span");
            span.style.backgroundColor = "green";
            span.textContent = selectedText;

            const parentElement = range.commonAncestorContainer.parentElement;

            if (parentElement?.classList.contains("katex")) {
              parentElement.style.backgroundColor = "green";
            } else if (!parentElement?.classList.contains("head")) {
              range.deleteContents();
              range.insertNode(span);
            }

            selection.removeAllRanges();

            copiedTextRef.current += `${selectedText}\n`;

            navigator.clipboard
              .writeText(copiedTextRef.current)
              .then(() => {
                console.log("Text copied to clipboard");
              })
              .catch((err) => {
                console.error("Could not copy text: ", err);
              });
          }
        }
      }
    };
    const messagesHTML = document.querySelectorAll(".message");
    messagesHTML.forEach((msg) => {
      msg.addEventListener("mouseup", handleMouseUp);
    });

    return () => {
      messagesHTML.forEach((msg) => {
        msg.removeEventListener("mouseup", handleMouseUp);
      });
    };
  }, [copyMode]);

  const handleBGChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Image = event.target?.result as string;

        const img = new Image();
        img.src = base64Image;

        img.onload = () => {
          const width = img.width;
          const height = img.height;
          const aspectRatio = width / height;

          localStorage.setItem("backgroundImage", base64Image);
          localStorage.setItem(
            "backgroundImageAspectRatio",
            aspectRatio.toString()
          );
          window.location.reload();
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const handleBGDelete = () => {
    dispatch(setBgImage({ image: null, aspectRatio: null }));
    localStorage.removeItem("backgroundImage");
    localStorage.removeItem("backgroundImageAspectRatio");
  };

  const chatRestart = () => {
    axios
      .post(DOMAIN + "api/chat/delete-conversation/")
      .then((resp) => {
        console.log(resp);
        dispatch(setMessages([]));
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className={`control-panel-parent ${showControlPanel ? "show" : ""}`}>
      <div className="panel-bg">
        <div className={`control-panel ${showControlPanel ? "show" : ""}`}>
          <div className="control-panel-border"></div>
          <div className="icons">
            <div className="icon marker">
              <div className="instruction">
                <div className="decor-circle-mini decor-circle"></div>
                <div className="decor-circle-middle decor-circle"></div>
                <div className="decor-circle-big decor-circle"></div>Удобное
                копирование
              </div>
              {!copyMode ? (
                <svg
                  onClick={() => dispatch(setCopyMode(!copyMode))}
                  viewBox="0 0 20 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.6469 0.140765C14.0897 -0.0658938 14.6156 -0.0438085 15.0367 0.199133C15.4579 0.442076 15.7143 0.871231 15.7143 1.33333V9.69009C16.5683 10.1512 17.1429 11.013 17.1429 12V15.3705C17.1429 15.5775 17.1944 15.7816 17.2937 15.9668L19.5476 20.174C19.8451 20.7295 20 21.3419 20 21.9628V30.5714C20 31.3604 19.3604 32 18.5714 32C17.7824 32 17.1429 31.3604 17.1429 30.5714V23.3333H2.85714V30.5714C2.85714 31.3604 2.21755 32 1.42857 32C0.639593 32 0 31.3604 0 30.5714V22.5659C0 22.4461 0.00577143 22.3265 0.0172286 22.2076C0.00588571 22.14 0 22.0707 0 22C0 21.7328 0.0842 21.484 0.229171 21.2753C0.270457 21.1624 0.317214 21.0509 0.369386 20.9413L2.73401 15.9756C2.8152 15.8052 2.85714 15.6207 2.85714 15.4341V12C2.85714 11.013 3.43171 10.1512 4.28571 9.69009V5.33333C4.28571 4.82831 4.59143 4.36663 5.07541 4.14076L13.6469 0.140765ZM12.8571 3.49072V9.33333H7.14286V6.15739L12.8571 3.49072ZM14.7381 17.1593L16.6171 20.6667H3.6268L5.3449 17.0587C5.58844 16.5472 5.71429 15.9937 5.71429 15.4341V12H14.2857V15.3705C14.2857 15.9915 14.4406 16.6039 14.7381 17.1593Z"
                    fill="#9C99AE"
                  />
                </svg>
              ) : (
                <svg
                  onClick={() => dispatch(setCopyMode(!copyMode))}
                  viewBox="0 0 22 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.6469 2.14077C16.0897 1.93411 16.6156 1.95619 17.0367 2.19913C17.4579 2.44208 17.7143 2.87123 17.7143 3.33333V11.6901C18.5683 12.1512 19.1429 13.013 19.1429 14V17.3705C19.1429 17.5775 19.1944 17.7816 19.2937 17.9668L21.5476 22.174C21.8451 22.7295 22 23.3419 22 23.9628V32.5714C22 33.3604 21.3604 34 20.5714 34C19.7824 34 19.1429 33.3604 19.1429 32.5714V25.3333H4.85714V32.5714C4.85714 33.3604 4.21755 34 3.42857 34C2.63959 34 2 33.3604 2 32.5714V24.5659C2 24.4461 2.00577 24.3265 2.01723 24.2076C2.00589 24.14 2 24.0707 2 24C2 23.7328 2.0842 23.484 2.22917 23.2753C2.27046 23.1624 2.31721 23.0509 2.36939 22.9413L4.73401 17.9756C4.8152 17.8052 4.85714 17.6207 4.85714 17.4341V14C4.85714 13.013 5.43171 12.1512 6.28571 11.6901V7.33333C6.28571 6.82831 6.59143 6.36663 7.07541 6.14076L15.6469 2.14077ZM14.8571 5.49072V11.3333H9.14286V8.15739L14.8571 5.49072ZM16.7381 19.1593L18.6171 22.6667H5.6268L7.3449 19.0587C7.58844 18.5472 7.71429 17.9937 7.71429 17.4341V14H16.2857V17.3705C16.2857 17.9915 16.4406 18.6039 16.7381 19.1593Z"
                    fill="#9C99AE"
                  />
                  <path
                    d="M5 5L1 1"
                    stroke="#9C99AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M5 1L1 5"
                    stroke="#9C99AE"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </div>
            <div className="icon change-fone">
              <div className="instruction">
                <div className="decor-circle-mini decor-circle"></div>
                <div className="decor-circle-middle decor-circle"></div>
                <div className="decor-circle-big decor-circle"></div>Изменение
                фона
              </div>

              {bgImage.image ? (
                <svg
                  onClick={handleBGDelete}
                  viewBox="0 0 34 38"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0277 32.0626V29.7001H10.5794C9.87003 29.7001 9.22519 29.3536 8.76091 28.7709C8.28373 28.2039 8 27.4164 8 26.5501C8 24.9279 9.03174 23.5734 10.3343 23.4159C10.4117 23.4001 10.4891 23.4001 10.5794 23.4001H29.9245C30.0148 23.4001 30.0922 23.4001 30.1695 23.4159C30.7886 23.4789 31.3303 23.8096 31.7429 24.3294C32.2717 24.9594 32.5554 25.8571 32.491 26.8336C32.3749 28.4874 31.1497 29.7001 29.7826 29.7001H23.4761V32.0626C23.4761 34.2361 22.0317 36.0001 20.2519 36.0001C18.4722 36.0001 17.0277 34.2361 17.0277 32.0626Z"
                    stroke="#9C99AE"
                    strokeWidth="2.46094"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M30.7886 9.6975L30.1696 23.4157C30.0922 23.4 30.0148 23.4 29.9245 23.4H10.5794C10.4891 23.4 10.4118 23.4 10.3344 23.4157L9.71533 9.6975C9.48319 6.90975 11.2758 4.5 13.5586 4.5H26.9454C29.2281 4.5 31.0208 6.90975 30.7886 9.6975Z"
                    stroke="#9C99AE"
                    strokeWidth="2.46094"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.0802 4.5V12.375"
                    stroke="#9C99AE"
                    strokeWidth="2.46094"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.2518 4.5V7.65"
                    stroke="#9C99AE"
                    strokeWidth="2.46094"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.25 6.25L1 1"
                    stroke="#9C99AE"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                  />
                  <path
                    d="M6.25 1L1 6.25"
                    stroke="#9C99AE"
                    strokeWidth="1.75"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <label>
                  <svg
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7913 30.0001V27.6001H5.36895C4.44249 27.6001 3.60025 27.2481 2.99384 26.6561C2.37058 26.0801 2 25.2801 2 24.4001C2 22.7521 3.34758 21.3761 5.0489 21.2161C5.14997 21.2001 5.25104 21.2001 5.36895 21.2001H30.6361C30.754 21.2001 30.8551 21.2001 30.9561 21.2161C31.7647 21.2801 32.4722 21.6161 33.0112 22.1441C33.7018 22.7841 34.0724 23.6961 33.9882 24.6881C33.8366 26.3681 32.2363 27.6001 30.4508 27.6001H22.2137V30.0001C22.2137 32.2081 20.3271 34.0001 18.0025 34.0001C15.6779 34.0001 13.7913 32.2081 13.7913 30.0001Z"
                      stroke="#9C99AE"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M31.7647 7.28L30.9561 21.216C30.8551 21.2 30.754 21.2 30.6361 21.2H5.36896C5.25105 21.2 5.14998 21.2 5.04891 21.216L4.24036 7.28C3.93715 4.448 6.27858 2 9.2601 2H26.7449C29.7265 2 32.0679 4.448 31.7647 7.28Z"
                      stroke="#9C99AE"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.2476 2V10"
                      stroke="#9C99AE"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.0024 2V5.2"
                      stroke="#9C99AE"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <input type="file" hidden onChange={handleBGChange} />
                </label>
              )}
            </div>
            <div className="icon delete">
              <div className="instruction">
                <div className="decor-circle-mini decor-circle"></div>
                <div className="decor-circle-middle decor-circle"></div>
                <div className="decor-circle-big decor-circle"></div>Начать чат
                заново
              </div>
              <svg
                onClick={chatRestart}
                viewBox="0 0 32 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30 7.33325H2"
                  stroke="#9C99AE"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M27.2545 11.7778L26.4968 24.0429C26.2053 28.7627 26.0595 31.1227 24.6348 32.5613C23.2102 34 21.0189 34 16.6364 34H15.3628C10.9802 34 8.789 34 7.36428 32.5613C5.93959 31.1227 5.79382 28.7627 5.50229 24.0429L4.74475 11.7778"
                  stroke="#9C99AE"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M11.8823 16.2222L12.7058 25.111"
                  stroke="#9C99AE"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M20.1175 16.2222L19.294 25.111"
                  stroke="#9C99AE"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                />
                <path
                  d="M6.94141 7.33332C7.03344 7.33332 7.07946 7.33332 7.12118 7.33219C8.47743 7.29508 9.67389 6.36428 10.1354 4.98723C10.1496 4.94487 10.1641 4.89776 10.1932 4.8035L10.3532 4.2857C10.4897 3.84369 10.5579 3.62268 10.6484 3.43502C11.0096 2.68635 11.6779 2.16647 12.4501 2.03337C12.6437 2 12.8596 2 13.2913 2H18.7091C19.1408 2 19.3567 2 19.5502 2.03337C20.3225 2.16647 20.9907 2.68635 21.3519 3.43502C21.4425 3.62268 21.5107 3.84369 21.6473 4.2857L21.8072 4.8035C21.8362 4.89763 21.8508 4.9449 21.865 4.98723C22.3265 6.36428 23.5229 7.29508 24.8793 7.33219C24.9209 7.33332 24.9669 7.33332 25.0589 7.33332"
                  stroke="#9C99AE"
                  strokeWidth="2.5"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={() => setShowControlPanel(!showControlPanel)}
        className="icons-mobile-toggler-parent"
      >
        <div className="icons-mobile-toggler">
          <div className="icons-mobile-toggler-border"></div>
          <svg
            width="18"
            height="14"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13H17"
              stroke="#706E7C"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 7H17"
              stroke="#706E7C"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 1H17"
              stroke="#706E7C"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
