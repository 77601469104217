import { BgImageType, MeType, MessageType, WinSize } from "./interfaces";

export interface ActionType {
  type: string;
  data: any;
}

export const setShowLoginForm = (show: boolean) => ({
  type: "setShowLoginForm",
  data: { show: show },
});

export const setShowChat = (show: boolean) => ({
  type: "setShowChat",
  data: { show: show },
});

export const setMe = (me: MeType) => ({
  type: "setMe",
  data: { me: me },
});

export const setBgImage = (bgImage: BgImageType) => ({
  type: "setBgImage",
  data: { bgImage: bgImage },
});

export const setMessages = (messages: MessageType[]) => ({
  type: "setMessages",
  data: { messages: messages },
});

export const setCopyMode = (copyMode: boolean) => ({
  type: "setCopyMode",
  data: { copyMode: copyMode },
});
export const setShowPayForm = (showPayForm: boolean) => ({
  type: "setShowPayForm",
  data: { showPayForm: showPayForm },
});
export const setNeedReplenishMessage = (needReplenishMessage: string) => ({
  type: "setNeedReplenishMessage",
  data: { needReplenishMessage: needReplenishMessage },
});
export const setWinSize = (winSize: WinSize) => ({
  type: "setWinSize",
  data: { winSize: winSize },
});

export const setIsKeyboardOpen = (isKeyboardOpen: boolean) => ({
  type: "setIsKeyboardOpen",
  data: { isKeyboardOpen: isKeyboardOpen },
});
